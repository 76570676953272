import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import NavBar from '../Components/NavBar'
import CardDashboard from '../Components/CardDashboard'
import DashboardBtns from '../Components/DashboardBtns'
import axiosCSRF from '../Axios/axiosConfig'


const Dashboard = () => {
    const taches = ['Mes Rendez-vous','Mes Fiches','Mes Factures','Gestion Stock','Medicament','Déconnexion']
    const colors = ['#00AE9F','#FF6F61','#274B6B','#F5E6C4','#EAD1D1','#4D4B4B']
    const lien = ['/MesRDV','/ttfiches','/mesFacture','/stock','/Medicament','/']



    useEffect(() => {
      const statue = localStorage.getItem('statue');
  
      const sendSms = async () => {
        try {
          if (statue === null) {
            await axiosCSRF.get('/send-sms-reminders'); 
            localStorage.setItem('statue', '1');
          }
        } catch (error) {
          console.error('Error sending SMS reminder:', error);
        }
      };
  
      sendSms();
    }, []);

  return (
    <div>
      <NavBar/>
      <h1 className='text-danger text-center mb-5'>Bonne Journée Docteur</h1>
      <div className='d-flex flex-row flex-wrap gap-5 m-auto'>
       {taches.map((tache,index)=>(
        <CardDashboard key={index} tache={tache} color={colors[index]} index={index} lien={lien[index]}/>
       ))}
      </div>
       <DashboardBtns/>
      <Footer />
    </div>
  )
}

export default Dashboard
