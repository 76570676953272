import axiosCSRF from '../Axios/axiosConfig';

export const GetNonPayer = async()=>{
  
    const token = localStorage.getItem('token')


   const response = await axiosCSRF.get('/nonpayer', { 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })


    return response.data.factures

}