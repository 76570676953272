import React from 'react'
import Slider from 'react-slick'
import RoundedCard from './RoundedCard'
const ServicesCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 887,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint : 650,
                  settings : {
                    slidesToShow: 2,
                  }
                },
                {
                    breakpoint :436,
                    settings:{
                        slidesToShow:1,
                    }
                }
        ]
      };
    const Services=["Vaccination","Consultation",'Chirurgie',"toilettage"]

  return (
    <div className='mx-auto mt-3'>
      <Slider {...settings}>
        {
            Services.map((service,index)=>(
                <RoundedCard service={service} key={index}/>
            ))
        }
      </Slider>
    </div>
  )
}

export default ServicesCarousel
