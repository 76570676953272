import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import FormLogin from '../Components/FormLogin'

const Login = () => {
  return (
    <div>
      <NavBar/>
      <h1 className='text-center text-success'>Login</h1>
      <FormLogin/>
      <Footer/>
    </div>
  )
}

export default Login
