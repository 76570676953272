import axiosCSRF from "../Axios/axiosConfig";



export const GetUser = async () => {
    try {
        const response = await axiosCSRF.get('api/user')
        localStorage.setItem('token', response.data.token);
        
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
}
