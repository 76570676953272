import axios from 'axios';


const axiosCSRF = axios.create({
  baseURL: 'https://api.medicapet.net',
  withCredentials: true,
});

const initializeAxiosCSRF = async () => {
  try {
    
    const response  = await axiosCSRF.get('/Csrf');


    
   const csrfToken = response.data.csrf_token;


    
    axiosCSRF.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;


  } catch (error) {
    console.error('Failed to initialize CSRF:', error);
  }
};


initializeAxiosCSRF();

export default axiosCSRF;
