import React from 'react'
import NavBar from '../Components/NavBar'
import AdminNavBar from '../Components/AdminNavBar'
import TableDesFiches from '../Components/TableDesFiches'

const UserFiches = () => {
  return (
    <div>
      <NavBar/>
      <AdminNavBar/>
      <TableDesFiches/>
    </div>
  )
}

export default UserFiches
