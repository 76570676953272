import React from 'react'
import NavBar from '../Components/NavBar'
import AdminNavBar from '../Components/AdminNavBar'
import ChercherFiche from '../Components/ChercherFiche'
const HomeFiche = () => {
  return (
    <div>
      <NavBar/>
        <AdminNavBar/>
        <ChercherFiche/>
    </div>
  )
}

export default HomeFiche
