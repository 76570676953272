import React from 'react'
import Card from 'react-bootstrap/Card';
const Card1 = ({titre,image,text}) => {
  return (
    <Card style={{ width: '18rem' }} className='mx-3'>
    <Card.Img variant="top" src={image} className='w-25 mx-auto mt-3' alt={titre}/>
    <Card.Body>
      <Card.Title className='text-center'>{titre}</Card.Title>

      <Card.Text className='mt-4' style={{ whiteSpace: 'pre-line' }}>
        {text}
      </Card.Text>
      
    </Card.Body>
  </Card>
  )
}

export default Card1
