import React from 'react'
import '../Styles/Dashboard.css'
import Notification from './Notification'
import { Logout } from '../Functions/Logout'

const CardDashboard = ({color,tache,index,lien}) => {
const bgClr = color
const Tcolor = index === 4 ? 'black' : index === 3 ? '#00AE9F' : 'white'
  return (
    <div 
    onClick={async() => {
      if (index === 5) {
          await Logout()
          window.location.href='/'
      } else {
          window.location.href = lien;
      }
  }}
    className='TableauCard mx-auto d-flex align-items-center justify-content-center fw-bold btn' 
    style={{backgroundColor : bgClr , color : Tcolor  }}>
      <h2>{tache}</h2>
      {index === 3 && <Notification/>}
    </div>
  )
}

export default CardDashboard
