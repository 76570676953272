import React, { useState, useEffect } from 'react';
import NavBar from '../Components/NavBar';
import LandingSection from '../Components/LandingSection';
import IntroAcceuil from '../Components/IntroAcceuil';
import Cards from '../Components/Cards';
import CardsCarousel from '../Components/CardsCarousel';
import NosService from '../Components/NosService';
import NosTarif from '../Components/NosTarif';
import PrendreRDVSection from '../Components/PrendreRDVSection';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 637);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 637);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Helmet>
        <title>MedicaPet - Votre Cabinet Vétérinaire de Confiance en Tunisie</title>
        <meta name="description" content="Bienvenue chez MedicaPet, la clinique vétérinaire de référence a la Marsa. Nous offrons des services de médecine vétérinaire, soins pour animaux, consultations, vaccination, et plus encore." />
        <meta name="keywords" content="clinique vétérinaire, vétérinaire Tunisie, soins pour animaux, consultation vétérinaire, vétérinaire à domicile, médecine vétérinaire, vaccination animaux, services vétérinaires" />
        <meta property="og:title" content="MedicaPet - Votre Clinique Vétérinaire de Confiance en Tunisie" />
        <meta property="og:description" content="Découvrez MedicaPet, une clinique vétérinaire professionnelle offrant des soins de qualité pour vos animaux de compagnie. Nos services incluent la médecine vétérinaire, les consultations, la vaccination et bien plus." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.medicapet.net" />
        <meta property="og:image" content="https://www.medicapet.net/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="MedicaPet - Votre Clinique Vétérinaire de Confiance en Tunisie" />
        <meta name="twitter:description" content="Découvrez MedicaPet, une clinique vétérinaire professionnelle offrant des soins de qualité pour vos animaux de compagnie. Nos services incluent la médecine vétérinaire, les consultations, la vaccination et bien plus." />
        <meta name="twitter:image" content="https://www.medicapet.net/images/home-veterinaire.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.medicapet.net" />
      </Helmet>

      <NavBar />
      <LandingSection />
      <IntroAcceuil />
      {isMobile ? <CardsCarousel /> : <Cards />}
      <NosService />
      <NosTarif />
      <PrendreRDVSection />
      <Footer />
    </div>
  );
}

export default Home;
