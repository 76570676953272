import React from 'react'
import NavBar from '../Components/NavBar'
import FactureBody from '../Components/FactureBody'
const Factures = () => {
  return (
    <div>
      <NavBar/>
      <FactureBody/>
    </div>
  )
}

export default Factures
