import React from 'react'
import { Image } from 'react-bootstrap'
import '../Styles/RDV.css'
import ImgRdv from '../Images/RDVimg.jpg'
import FormRDV from './FormRDV'
const RdvFirstSec = () => {
  return (
    <div>
    <div className='CabinetContainer'>
     <Image src={ImgRdv} alt='veterinaire' className='ImageRDV'/>
     <div>
         <p className='text' >Votre <span>compagnon </span>
          mérite le meilleur soin possible, 
          et notre équipe de vétérinaires est là pour lui offrir 
          toute l'attention et le soin qu'il mérite. Ne laissez 
          pas passer l'opportunité de garantir la santé et le 
          bien-être de votre animal !!</p>
          <h3 className='text-center mt-4 fw-bold'>Prendre Mon <span>Rendez</span>-Vous</h3>
          <FormRDV/>
     </div>
   </div>
 </div>
  )
}

export default RdvFirstSec
