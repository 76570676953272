import React from "react";
import Slider from "react-slick";
import Clinique from '../Images/IconClinique.png'
import Horaire from '../Images/IconHoraire.png'
import Uregence from '../Images/IconUrgence.png'
import Card1 from "./Card";
import '../Styles/Slide.css'
function CardsCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const Titres=["Medicapet","Les horaires de la cabinet","Urgence Vétérinaires"]
const Images = [Clinique,Horaire,Uregence]
const text = ["votre cabinet vétérinaire de confiance pour des soins de qualité. Nous sommes spécialisés dans la prise en charge complète de vos animaux, avec des services adaptés à leurs besoins spécifiques.",
  "Lundi à vendredi :\n- De 9h à 18 h\nSamedi :\n- De 9h à 14h\nDimanche fermé sur rendez vous",
  "En cas d'urgence, nos vétérinaires sont disponibles 24/7 pour intervenir rapidement et efficacement. Contactez-nous immédiatement sur 98 807 807 pour toute situation critique concernant la santé de votre animal."
]
  return (
    <div className="container-slide mx-auto">
    <Slider {...settings}>
    {Titres.map((titre,index)=>(
        <Card1 titre={titre} index={index} image={Images[index]} text={text[index]}/>
    ))}
    </Slider>
  </div>
  );
}

export default CardsCarousel;