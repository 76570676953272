import axiosCSRF from "../Axios/axiosConfig";

export const CreeAnimal = async(data) => {  
    const requestData = {
        nom: data.animal, 
        num_tel: data.ntel,
        nom_proprio: data.Proprio,
        espece: data.espx,
    };

    await axiosCSRF.post('/NewAnimal', requestData);

};
