export const encryptPassword = (password) => {
    const rot13Encrypt = (str) => {
        return str.replace(/[A-Za-z]/g, (char) => {
            const code = char.charCodeAt(0);
            return String.fromCharCode(
                code >= 65 && code <= 90 ? // Uppercase A-Z
                    ((code - 65 + 13) % 26) + 65 :
                    ((code - 97 + 13) % 26) + 97 // Lowercase a-z
            );
        });
    };

    // Encrypt the password using ROT13
    return rot13Encrypt(password);
};
