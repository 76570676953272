import React from 'react'
import Calendar from '../Components/Calendar'
import NavBar from '../Components/NavBar'
import AdminNavBar from '../Components/AdminNavBar'
const MesRDV = () => {
  return (
    <div>
      <NavBar/>
      <AdminNavBar/>
      <Calendar/>
    </div>
  )
}

export default MesRDV
