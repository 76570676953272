import React, { useEffect, useState } from 'react'
import '../Styles/Notifcations.css'
import { CountQsec } from '../Functions/CountQse'
const Notification = () => {
const [nombre,setNombre]=useState(0)
  useEffect(()=>{

    const fetch = async()=>{
      setNombre(await CountQsec())
    } 
    fetch()
  },[nombre])

  return (
    <>
    {nombre > 0 &&  <div className='Notifcation'>
      <h3>{nombre}</h3>
    </div> }
   
    </>
  )
}

export default Notification
