import React, { useEffect, useState } from 'react'
import { GetProduits } from '../Functions/GetProduits'
import { VscAdd } from "react-icons/vsc";
import { FaMinus } from "react-icons/fa6";
import { MinusOne } from '../Functions/MinusOne';
import { AddOne } from '../Functions/AddOne';
import { FaTrashAlt } from "react-icons/fa";
import { SupprimerProd } from '../Functions/supprimerProd';


const TableauProduit = () => {
    const [produits,setProduits]=useState([])
    const [reload,setReload]=useState(true)

const Add = async(id)=>{
  AddOne(id)
  setReload(true)
}


const sub = async(id)=>{
  MinusOne(id)
  setReload(true)
}

    useEffect(()=>{
        const fetchData = async ()=>{
            setProduits(await GetProduits())
        }
        if(reload){
          fetchData()
          setReload(false)
        }
    },[reload])

   
const supprimer = async(id)=>{
    await SupprimerProd(id)

    window.location.reload()
}

  return (
    <div>
      {produits && 
      
      <table className='table mt-5 table-striped table-hover'>
        <thead>
            <tr className='text-center'>
                <th>TITRE</th>
                <th>Type</th>
                <th>Quantiter</th>
                <th>Fournisseur</th>
                <th>Modifier</th>
            </tr>
        </thead>
        <tbody>
        {produits.map((produit,index)=>(
           <tr key={index} 
           className={
            produit.Quantiter <= produit.Quantiter_securite
              ? produit.Priorite === 1
                ? 'text-center table-danger'
                : 'text-center table-warning'
              : 'text-center'
          }>
            <td>{produit.Titre}</td>
            <td>{produit.type}</td>
            <td>{produit.Quantiter}</td>
            <td>{produit.fournisseur}</td>
            <td colSpan="3">
              <FaMinus className='mx-2'
              onClick={()=>{sub(produit.id)}}
              />
              <VscAdd className='mx-1'
              onClick={()=>{Add(produit.id)}}
              />
    <td className='text-danger btn' onClick={()=>{supprimer(produit.id)}}><FaTrashAlt /></td>

            </td>
           </tr>
        ))}
        </tbody>
      </table>

      }
    </div>
  )
}

export default TableauProduit
