import React, { useState, useRef } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { VscAdd } from "react-icons/vsc";
import ReactToPrint from 'react-to-print';
import { PostFacture } from '../Functions/PostFacture';
import '../Styles/Facture.css';
import { IoIosClose } from "react-icons/io";

// Custom toggle for the dropdown
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="dropdown-toggle-custom"
    >
        {children} &#x25bc;
    </a>
));

// Custom menu for filtering the dropdown
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={`custom-dropdown-menu ${className}`}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="dropdown-search-input"
                    placeholder="Type to filter services..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled dropdown-items">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase())
                    )}
                </ul>
            </div>
        );
    }
);

const FactureBody = () => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const numtel = params.get('numtel');
    const nom = params.get('nom');
    const date = new Date();
    const [totale, setTotale] = useState(0);
    const [selectedService, setSelectedService] = useState(null);
    const [rows, setRows] = useState([]);
    const [statue, setStatue] = useState(null);
    const printRef = useRef();

    const services = [
        { type: 'Consultation du Jour', prix: 40 },
        { type: 'Consultation du Nuit', prix: 50 },
        { type: 'Consultation jours fériés', prix: 50 },
        { type: 'Consultation jours fériés de Nuit', prix: 60 },
        { type: 'Vaccinat (Chat)', prix: 70 },
        { type: 'Vaccinat (Chien)', prix: 60 },
        { type: 'Castrat (Chat)', prix: 100 },
        { type: 'Castrat (Chien)', prix: 120 },
        { type: 'Ovariectomie (Chat)', prix: 140 },
        { type: 'Ovariectomie (Chien)', prix: 200 },
        { type: 'Ovario-hystérectomie (Chat)', prix: 170 },
        { type: 'Ovario-hystérectomie (Chien)', prix: 250 },
        { type: 'Anesthésie', prix: 30 },
        { type: 'Suture plaie (Chat)', prix: 60 },
        { type: 'Suture plaie (Chien)', prix: 80 },
        { type: 'Détartrage', prix: 100 },
        { type: 'Drainage abcès', prix: 80 },
        { type: 'Amputation d\'un membre (Chat)', prix: 120 },
        { type: 'Amputation d\'un membre (Chien)', prix: 160 },
        { type: 'Enucléation de l\'œil', prix: 120 },
        { type: 'Prise de sang', prix: 20 },
        { type: 'Perfusion', prix: 40 },
        { type: 'Puce electronique', prix: 100 },
        { type: 'Hospitalisation', prix: 50 },
        { type: 'Analyse', prix: 30 },
        { type: 'toilettage', prix: 60 }
    ];

    const handleAddition = () => {
        if (selectedService !== null) {
            const selectedServiceItem = services[selectedService];
            setRows([...rows, selectedServiceItem]);
            setTotale(totale + selectedServiceItem.prix);
            setSelectedService(null);
        }
    };

    // Function to remove a service from the invoice
    const handleRemoval = (index) => {
        const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
        setTotale(updatedRows.reduce((total, row) => total + row.prix, 0)); // Recalculate total
    };

    const submit = () => {
        PostFacture({ numtel: numtel, somme: totale, date: date.toLocaleDateString(), statue: statue });
    };

    return (
        <div className='facture-container mt-5'>
            <div ref={printRef} className='facture-content'>
                <h3 className='text-center'>Facture</h3>
                <div className='facture-details mx-5 mt-4'>
                    <h6 className='m-3'>Date: {date.toLocaleDateString()}</h6>
                    <h6 className='m-3'>N°Tel: {numtel}</h6>
                    <h6 className='m-3'>Nom : {nom} </h6>
                </div>
                <table className='table facture-table w-75 mx-auto mt-5'>
                    <thead>
                        <tr>
                            <th>Produit</th>
                            <th>Prix</th>
                            <th>Quantité</th>
                            <th>Montant</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index} className="row-hover">
                                <td>{row.type}</td>
                                <td>{row.prix} TTC</td>
                                <td>1</td>
                                <td>{row.prix} TTC</td>
                                <td>
                                    <span className="remove-button">
                                        <IoIosClose className='text-danger icon-large' onClick={() => handleRemoval(index)} />
                                    </span>
                                </td>
                            </tr>
                        ))}
                        <tr className="no-print">
                            <td colSpan="5" className="text-center">
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        Ajouter un service
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                        {services.map((service, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                eventKey={index}
                                                onClick={() => setSelectedService(index)}
                                            >
                                                {service.type}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Button onClick={handleAddition} className="ml-3 mx-3 btn-sm add-btn">
                                    <VscAdd />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="3" className="text-right">Total:</td>
                            <td>{totale} TTC</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className='d-flex justify-content-center align-items-center payment-status'>
                <div className='form-check form-check-inline'>
                    <input 
                        type="radio" 
                        name="statue" 
                        id="Payer" 
                        value='Payer' 
                        className='form-check-input'
                        onChange={(e) => setStatue(e.target.value)}
                    />
                    <label htmlFor="Payer" className='form-check-label'>Payer</label>
                </div>
                <div className='form-check form-check-inline'>
                    <input 
                        type="radio" 
                        name="statue" 
                        id="nonpayer" 
                        value='Non Payer' 
                        className='form-check-input'
                        onChange={(e) => setStatue(e.target.value)}
                    />
                    <label htmlFor="nonpayer" className='form-check-label'>Non Payer</label>
                </div>
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <ReactToPrint 
                    trigger={() => <button className="btn btn-danger mx-2">Imprimer</button>}
                    content={() => printRef.current}
                    documentTitle='Facture'
                    pageStyle='@page { size: auto; margin: 20mm; }'
                />
                <Button variant='primary' className='mx-2' onClick={submit}>Enregistrer</Button>
            </div>
        </div>
    );
};

export default FactureBody;
