import React from 'react'
import Departement from './Departement'
import { BsBoxSeam } from "react-icons/bs";
import { RiBillLine } from "react-icons/ri";
import { FaRegCalendarAlt } from "react-icons/fa";
import '../Styles/AdminNavBar.css';
const AdminNavBar = () => {

    const icons = [<BsBoxSeam className='icon'/>,<RiBillLine className='icon'/>,<FaRegCalendarAlt className='icon'/>]
    const titre = ['Stock','Factures','Rendez-vous']
    const lien = ['/stock','/mesFacture','/MesRDV']
  return (
    <div className='d-flex flex-row justify-content-center mt-4'>
        {icons.map((icon,index)=>(
            <Departement key={index} icon={icon} titre={titre[index]} lien={lien[index]}/>
        ))}
    </div>
  )
}

export default AdminNavBar
