import React, { useEffect, useState } from 'react';
import '../Styles/RDV.css';
import Calendar from 'react-calendar';
import { useFormik } from 'formik';
import { Button } from 'react-bootstrap';
import RdvSchema from '../Schemas/RdvSchema';
import FormError from './FormError';
import { GetTempss } from '../Functions/GetTemps';
import { PrendreRdv } from '../Functions/PrendreRdv';

const FormRDV = () => {
  const [reserver, setReserver] = useState(null);
  const [value, setDate] = useState(new Date());
  const [tempPris, setTempPris] = useState('');
  const [isDateValid, setIsDateValid] = useState(true); 
  const currentDate = new Date(); 

  const temps = [
    '08:30', '09:30', '10:30',
    '11:30', '12:00', '13:00', '14:00',
    '15:30', '16:30', '17:30', '18:30'
  ];

  const samedi = [
    '08:30', '09:30', '10:30',
    '11:30', '12:00', '13:00', '14:00'
  ];

  const submit = async (data) => {
    PrendreRdv(data);
  };

  const { handleBlur, handleChange, handleSubmit, touched, values, errors } = useFormik({
    initialValues: {
      animal: '',
      ntel: '',
      Proprio: '',
      espx: 'Male',
      DateRDV: new Date()
    },
    validationSchema: RdvSchema,
    onSubmit: (values) => {
      values.DateRDV = value;
      values.DateRDV = new Date(values.DateRDV).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
      values.temp = tempPris;
      submit(values);
    }
  });

 
  function compareDateAndTime(selectedDate, tempPris, currentDate) {
    if (!tempPris) return true; 

    const [hours, minutes] = tempPris.split(":").map(Number);
    const dateToCompare = new Date(selectedDate);
    dateToCompare.setHours(hours, minutes, 0, 0);

    const selectedDay = new Date(selectedDate.setHours(0, 0, 0, 0));
    const currentDay = new Date(currentDate.setHours(0, 0, 0, 0));

    if (selectedDay < currentDay) {
      return false;
    }

    
    if (selectedDay.getTime() === currentDay.getTime()) {
      return dateToCompare >= currentDate;
    }

    return true;
  }

  
  useEffect(() => {
    const isValid = compareDateAndTime(value, tempPris, currentDate);
    setIsDateValid(isValid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, tempPris]);

  useEffect(() => {
    const fetchData = async () => {
      if (value) {
        let date = new Date(value).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        try {
          const reserved = await GetTempss(date);
          setReserver(reserved);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    fetchData();
  }, [value]);

  const reservedArray = reserver !== null ? Object.values(reserver) : [];

  const isSaturday = value.getDay() === 6;
  const isSunday = value.getDay() === 0;

  const availableTimes = isSaturday
    ? samedi
    : temps.filter((temp) => {
        const [hours, minutes] = temp.split(":").map(Number);
        const tempDate = new Date(value);
        tempDate.setHours(hours, minutes, 0, 0);
        return tempDate > currentDate;
      });



      useEffect(() => {
        const reservedArray = reserver !== null ? Object.values(reserver) : [];
        const isSaturday = value.getDay() === 6;
        const isSunday = value.getDay() === 0;
      
        const availableTimes = isSaturday
          ? samedi
          : temps.filter((temp) => {
              const [hours, minutes] = temp.split(":").map(Number);
              const tempDate = new Date(value);
              tempDate.setHours(hours, minutes, 0, 0);
              return tempDate > currentDate && !reservedArray.includes(temp + ':00');
            });
      
        // Update the tempPris based on available times
        if (availableTimes.length > 0 && !isSunday) {
          setTempPris(availableTimes[0]);
          console.log('Available time:', tempPris); // Log the newly available time
        } else {
          setTempPris(''); // Clear tempPris if no available times
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [reserver, value]); // Depend on reserver and value

  return (
    <div>
      <form method='POST' className='RDVForm' onSubmit={handleSubmit}>
        <div className='w-lg-50 leftForm'>
          <input
            type='text'
            name='animal'
            placeholder="Nom de l'animal"
            className='input'
            value={values.animal}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormError displayed={errors.animal && touched.animal} error={errors.animal} />

          <div className='RadioGroup'>
            <input
              type='radio'
              id="male"
              name='espx'
              value='Male'
              checked={values.espx === 'Male'}
              onChange={handleChange}
              className='RedioIn'
            />
            <label htmlFor="male">Male</label>
            <br />
            <input
              type='radio'
              id="female"
              name='espx'
              value='Femelle'
              checked={values.espx === 'Femelle'}
              onChange={handleChange}
              className='RedioIn'
            />
            <label htmlFor="female">Femelle</label>
          </div>


          <input
            type='text'
            value={values.Proprio}
            placeholder="Nom Proprio"
            name='Proprio'
            className='input'
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormError displayed={errors.Proprio && touched.Proprio} error={errors.Proprio} />
          <input
            type='number'
            placeholder="N°tel"
            name='ntel'
            className='input'
            value={values.ntel}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormError displayed={errors.ntel && touched.ntel} error={errors.ntel} />
        </div>

        <div className='w-lg-50 d-flex flex-column'>
          <Calendar onChange={setDate} value={value} className="mx-auto" name="date" />

          {isSunday && (
            <FormError displayed={true} error={"Les rendez-vous ne sont pas autorisés les dimanches"} />
          )}

          {!isDateValid && (
            <FormError displayed={true} error={"La date du rendez-vous ne peut pas être dans le passé"} />
          )}

          <select
            className="form-select mt-3 w-sm-50"
            name="heure"
            onChange={(e) => setTempPris(e.target.value)}
            value={tempPris}
            disabled={isSunday}
            required
          >
            {availableTimes.map((temp) => (
              !reservedArray.includes(temp + ':00') && (
                <option key={temp} value={temp}>
                  {temp}
                </option>
              )
            ))}
          </select>

          <Button type="submit" className='btn mt-3 w-50 mx-auto' disabled={isSunday || !isDateValid}>
            Prendre Rendez-vous
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormRDV;
