import React from 'react'
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
const NosTarif = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className='text-center mt-5'>
      <h1>Actualités</h1>
      <div className="slider-container mt-3 mb-5">

      <Slider {...settings}>

<Card style={{ width: '18rem' }}>
<Card.Body>
  <Card.Title>Ouverture</Card.Title>
  <Card.Text className='text-center '>
  Ouverture de Medicapet ! Demain, rejoignez-nous pour découvrir nos services vétérinaires
   de qualité, incluant médecine interne, chirurgie et vaccinations. Votre compagnon mérite
    le meilleur, et notre équipe est prête à prendre soin de lui ! 🐾
  </Card.Text>
  <Button variant="primary">Lire Plus</Button>
</Card.Body>
</Card>

<Card style={{ width: '18rem' }}>
<Card.Body>
  <Card.Title>Horaires</Card.Title>
  <Card.Text>
  🐾 Horaires d'ouverture de Medicapet 🕒 :

Lundi à vendredi : de 9h à 18h
Samedi : de 9h à 14h
Dimanche : fermé, uniquement sur rendez-vous

Notre équipe est prête à accueillir votre compagnon pour tous ses besoins vétérinaires ! 🐶❤️
  </Card.Text>
  <Button variant="primary">Lire Plus</Button>
</Card.Body>
</Card>


<Card style={{ width: '18rem' }}>
<Card.Body>
  <Card.Title>  La Quantité d'Eau que Doit Boire Votre Animal de Compagnie</Card.Title>
  <Card.Text>
  L'hydratation est essentielle pour la santé des animaux de compagnie. Un chien doit boire environ 30 à 50 ml d'eau par kilogramme de poids corporel par jour, tandis qu'un chat a besoin de 50 à 100 ml. Il est crucial de surveiller leurs habitudes de boisson et de s'assurer qu'ils aient toujours accès à de l'eau fraîche pour prévenir la déshydratation et des problèmes de santé.
   </Card.Text>
  <Button variant="primary">Lire Plus</Button>
</Card.Body>
</Card>

</Slider>
      </div>
    </div>
  )
}

export default NosTarif
