import * as Yup from 'yup';

const RdvSchema = Yup.object({
  animal: Yup.string()
    .required('Nom de l\'animal est requis').min(2),
  
  ntel: Yup.number()
    .required('Numéro de téléphone est requis')
    .positive('Le numéro de téléphone doit être positif')
    .integer('Le numéro de téléphone doit être un nombre entier')
    .min(10000000, 'Le numéro de téléphone doit avoir au moins 8 chiffres')
    .max(99999999, 'Le numéro de téléphone doit avoir au plus 8 chiffres'),

  Proprio: Yup.string()
    .required('Nom du propriétaire est requis'),

  espx: Yup.string()
    .oneOf(['Male', 'Femelle'], 'Le sexe est requis')
    .required('Le sexe est requis'),


});

export default RdvSchema;
