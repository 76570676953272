import React from 'react'
import '../Styles/About.css';
import cabinet from '../Images/cabinet.jpeg'

import ImageTextLayout from './ImageTextLayout'
const AboutHeadSec = () => {
    const text ="Le docteur vétérinaire Wiem Khallouli a fondé le cabinet vétérinaire MEDICAPET à la Marsa en 2024. Il est situé au 3 rue Abdallah Elmahdi, Sidi Abdelaziz, La Marsa, près du California Gym. Par son emplacement, il est facilement accessible depuis les différents quartiers de Gammarth et de La Marsa. Les différents espaces du cabinet sont agencés dans le but de garantir aux propriétaires et à leurs animaux les meilleures prestations en termes d'accueil et de soins "
  return (
    <div>
      <h1 className='text-center titreSec1'>Qui sommes-nous</h1>
      <ImageTextLayout 
      Img={cabinet}
      Titre={'Notre Cabinet Vétérinaire a La Marsa'}
      Text={text}
      alt={'Cabinet_medicapet'}
      />
    </div>
  )
}

export default AboutHeadSec
