import React from 'react'

const Departement = ({icon,titre,lien}) => {
  return (
    <div className='d-flex flex-row mx-3'
    onClick={()=>{window.location.href=lien}}
    >
      {icon}
      <h6>{titre}</h6>
    </div>
  )
}

export default Departement
