import axiosCsrf from '../Axios/axiosConfig'

const GetNumTel = async () => {

    const token = localStorage.getItem('token')
    
    const url = window.location.href;

    
    const urlObj = new URL(url);

    
    const params = new URLSearchParams(urlObj.search);

    
    const data = params.get('numtel'); 


   const response = await axiosCsrf.get(`/animals?numtel=${data}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },})

        return response.data.data;
}

export default GetNumTel;
