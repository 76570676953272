import Swal from "sweetalert2";
import axiosCSRF from "../Axios/axiosConfig";

export const PostProduit = async (data) => {
  try {
    const token = localStorage.getItem('token');

 
    const formData = new FormData();
    formData.append('produit', data.produit);
    formData.append('qte', data.qte);
    formData.append('qSec', data.qSec);
    formData.append('prix', data.prix);
    formData.append('fornisseur', data.fornisseur);
    formData.append('type', data.type);
    formData.append('image', data.image);  


    
    await axiosCSRF.post('/NewProd', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', 
      },
    }).then(response => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Produit ajouté avec succès.',
      });
    })
  } catch (error) {
    console.error("Error posting Produit:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Erreur lors de l\'ajout du produit.',
    });
    throw error;
  }
};
