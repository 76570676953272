import axiosCSRF from '../Axios/axiosConfig';

export const GetFacture = async()=>{
  
    const token = localStorage.getItem('token')


   const response = await axiosCSRF.get('/Factures', { 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })


    return response.data

}