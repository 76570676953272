import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

// Custom toggle for the dropdown
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        type="button"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="dropdown-toggle-custom"
    >
        {children} &#x25bc;
    </button>
));

// Custom menu for filtering the dropdown
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={`custom-dropdown-menu ${className}`}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="dropdown-search-input"
                    placeholder="Type to filter medications..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled dropdown-items">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase())
                    )}
                </ul>
            </div>
        );
    }
);

const MedicamentBody = () => {
    const [selectedMedication, setSelectedMedication] = useState(null);
    const [medicaments, setMedicaments] = useState([]);

    const medications = [
        "Indocollyre 0.1%: 1 goutte 4 a 6 fois par j pd 7 j",
        "Unigenta: 2 gouttes 4 a 6 fois par j pd 7 j",
        "Vit A collyre : 1 goutte 6 fois par j pd 15 j",
        "Tobracol: 1 goutte 2 fois par j pd 15 j",
        "Oflocol: 1 goutte 2 fois par j 5 j",
        "Augmentin100mg : 0,2 à 0,4 ml par kg",
        "Azro 0,25 ml par kg",
        "Onycal ou tercyd250 1cp par 8kg",
        "Shotapen: 1 à 2ml par 25 kg en scène",
        "Marbocyl: 1ml par 10 kg en IM/IV",
        "Spirovet 0,08 a 0,16 en IM",
        "Biogyl cp: 1/4 cp par 2 kg",
        "Doxy: 20mg par kg",
        "Dexalone 0,5ml par 10 kg",
        "Telfedine20mg : 1 cp par 5 kg",
        "Inflamyl 0,5 ml par kg",
        "Prifinial: 0,5 ml par 3 kg en IM IV",
        "Tolfine: 1 ml par 20 kg",
        "Meloxicam 1,5 mg par kg pd 1 mois",
        "Paradis sirop : 1 à 2ml par kg en per os",
        "Motilium 1%: sirop 1ml par kg en per os",
        "Nifroxid sirop 0,5 ml 2 fois par j pd 5j",
        "Tamizol sirop 3 ml 1 fois par j pd 5j",
        "Ultra levure : un sachet par j",
        "Atropine: une ampoule par 10 kg tte les 4 h",
        "Dicynone: 0,1 ml par kg IV IM",
        "Vit c : 1 à 5 ml chien/0,5 à 1 ml chat",
        "Caberlex 1cp par 100kg"
    ];

    const handleSelectMedication = (medication) => {
        setSelectedMedication(medication);
        setMedicaments([...medicaments, medication]);
    };

    return (
        <div>
            <h1 className='mt-5 text-center'>Mes Médicaments</h1>
            <Dropdown className='text-center mt-5'>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    {selectedMedication ? selectedMedication : "Sélectionner un médicament"}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                    {medications.map((medication, index) => (
                        <Dropdown.Item
                            key={index}
                            eventKey={index}
                            onClick={() => handleSelectMedication(medication)}
                        >
                            {medication}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {medicaments.length > 0 && (
                <div className="m-4">
                    <h3>Liste des Médicaments Sélectionnés</h3>
                    {medicaments.map((medic, index) => (
                        <p key={index}>- {medic}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MedicamentBody;
