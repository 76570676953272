import React, { useEffect, useState } from 'react';
import { GetFiche } from '../Functions/GetFiches';
import '../Styles/ficheinfo.css';
import { FaTrashAlt } from "react-icons/fa";
import { SupprimerFiche } from '../Functions/SuprimerFiche';

const TableDesFiches = () => {
  const [fiches, setFiches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = window.location.href;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const nom = params.get('nom');


const supprimer = async(id)=>{
  await SupprimerFiche(id)

  window.location.reload()
}

  useEffect(() => {
    const fetchFiches = async () => {
      try {
        setLoading(true);
        // Fetch fiches using the numtel prop
        const fichesData = await GetFiche();
        setFiches(fichesData);
      } catch (err) {
        setError('Failed to fetch fiches');
      } finally {
        setLoading(false);
      }
    };


      fetchFiches();
    
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      {fiches.length > 0 ? (
        fiches.map((animal, animalIndex) => (
          <div key={animalIndex}>
            <h3>Nom Animal : {animal.animal_name}</h3>
            <h6>Nom Proprio: {nom}</h6>
            <p>N°Tel: {animal.num_tel}</p>
            <h4>Fiches:</h4>
            {animal.fiches.length > 0 ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Motif</th>
                    <th>Date</th>
                    <th>Act</th>
                  </tr>
                </thead>
                <tbody>
                  {animal.fiches.map((fiche, ficheIndex) => (
                    <tr key={ficheIndex}>
                      <td className='text-danger btn' onClick={()=>{supprimer(fiche.id)}}><FaTrashAlt /></td>
                      <td>{ficheIndex + 1}</td>
                      <td>{fiche.motif}</td>
                      <td>{fiche.date}</td>
                      <td className='act'>{fiche.act}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Pas de fiche encore</p>
            )}
          </div>
        ))
      ) : (
        <p>Pas d'animaux trouvés</p>
      )}
    </div>
  );
};

export default TableDesFiches;
