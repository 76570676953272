
import FicheBody from '../Components/FicheBody'

import NavBar from '../Components/NavBar'



const Fiche = () => {



  return (
    <div>
      <NavBar/>
    <FicheBody/>
    </div>
  )
}

export default Fiche
