import './App.css';
import Home from './Pages/Home';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import QuiSommesNous from './Pages/QuiSommesNous';
import RendezVous from './Pages/RendezVous';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import { jwtDecode } from "jwt-decode";
import HomeFiche from './Pages/HomeFiche';
import Fiche from './Pages/Fiche';
import MesRDV from './Pages/MesRDV';
import ConsulterFiches from './Pages/ConsulterFiches';
import UserFiches from './Pages/UserFiches';
import Factures from './Pages/Factures';
import CreeFacture from './Pages/CreeFacture';
import MesFactures from './Pages/MesFactures';
import NonPayer from './Pages/NonPyaer';
import Stock from './Pages/Stock';
import FormProduit from './Components/FormProduit';
import DashboardIcon from './Components/DashboardIcon';
import Magasin from './Pages/Magasin';
import Medicament from './Pages/Medicament';
import NotFound from './Pages/NotFound';

function App() {

  const token = localStorage.getItem('token');
  const authorized = token ? jwtDecode(token).id : null;

  return (
    <div className='Mother'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<QuiSommesNous />} />
          <Route path='/RDV' element={<RendezVous />} />
          <Route path='/store' element={<Magasin />} />
          {authorized ? (
            <>
              <Route path='/Dashboard' element={<Dashboard />} />
              <Route path='/fiches' element={<HomeFiche />} />
              <Route path='/fiche' element={<Fiche />} />
              <Route path='/MesRDV' element={<MesRDV />} />
              <Route path='/ttfiches' element={<ConsulterFiches />} />
              <Route path='/userfiches' element={<UserFiches />} />
              <Route path='/facture' element={<Factures />} />
              <Route path='/CreeFacture' element={<CreeFacture />} />
              <Route path='/mesFacture' element={<MesFactures />} />
              <Route path='/NonPayer' element={<NonPayer />} />
              <Route path='/Stock' element={<Stock />} />
              <Route path='/AjouterProd' element={<FormProduit />} />
              <Route path='/Medicament' element={<Medicament />} />
            </>
          ) : (
            <Route path='/a52vdLT' element={<Login />} />
          )}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      {authorized && <DashboardIcon />}
    </div>
  );
}

export default App;
