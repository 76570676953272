import axiosCSRF from '../Axios/axiosConfig';

export const GetFiche = async()=>{
  
    const token = localStorage.getItem('token')

    const url = window.location.href;

    
    const urlObj = new URL(url);

    
    const params = new URLSearchParams(urlObj.search);

    
    const data = params.get('numtel'); 

   const response = await axiosCSRF.get(`ShowFiche?num_tel=${data}`, { 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })


    return response.data

}