import Swal from "sweetalert2";
import axiosCSRF from "../Axios/axiosConfig";

export const PostFiche = async (data) => {
  try {
    const token = localStorage.getItem('token');
    await axiosCSRF.post('/NewFiche', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Fiche Sauvgarder.',
      });
    })
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Attention Requise",
      text: "Veuillez verifier les données",
  }); 
  }
};
