import React, { useEffect, useState } from 'react';
import { GetFacture } from '../Functions/GetFacture';
import NavBar from '../Components/NavBar';
import '../Styles/MesFacture.css';
import { MdEditDocument } from "react-icons/md";
import { Button } from 'react-bootstrap';
import { ModiFacture } from '../Functions/ModifFacture';
import { GetNonPayer } from '../Functions/GetNonPayer';

const NonPayer = () => {
  const [factures, setFactures] = useState([]);
  const [edit, setEdit] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [sommes,setSommes]=useState([])
  const submit = async (id) => {
    try {
      await ModiFacture(id, inputValue);
      setEdit(null);
    } catch (error) {
      console.error('Error updating facture:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetFacture();
      const Sarray = await GetNonPayer()
      setFactures(data.factures);
      setSommes(Sarray)
    };
    fetchData();
  }, []);

  const handleEditClick = (factureId) => {
    if (edit === factureId) {
      setEdit(null);
    } else {
      setEdit(factureId); 
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="factures-container">
      <NavBar />
      <h2 className="title">Mes Factures</h2>
      <div className="facture-list">
        {factures.map((facture,index) => (
          facture.statue === 'Non Payer' &&  (
            <div key={index} className="facture-item">
              <p><strong>Date:</strong> {facture.date}</p>
              <p><strong>Numéro de Téléphone:</strong> {facture.num_tel}</p>
              <p><strong>Somme:</strong> {facture.somme} Dt</p>
              <p><strong>Statut:</strong> {facture.statue}</p>
              {sommes.map((somme)=>(
                // eslint-disable-next-line eqeqeq
                (somme.id_facture == facture.id && <p><strong>Reste a Payer : </strong>{somme.somme}Dt</p>)
              ))}
              <MdEditDocument 
                className='text-primary' 
                onClick={() => handleEditClick(facture.id)}
              />
              {edit === facture.id && 
                <div>
                  <input 
                    type='number' 
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <Button className='btn-sm' onClick={() => submit(facture.id)}>Modifier</Button>
                </div>
              }
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default NonPayer;
