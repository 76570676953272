import axiosCSRF from "../Axios/axiosConfig"


export const MinusOne = async(id)=>{
    const token = localStorage.getItem('token')
    await axiosCSRF.post('/Minus', {id : id} ,{
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
}