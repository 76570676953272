import Swal from "sweetalert2";
import axiosCSRF from "../Axios/axiosConfig";

export const PostFacture = async (data) => {
  try {
    const token = localStorage.getItem('token');
    await axiosCSRF.post('/NewFacture', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Facture Sauvgarder.',
      });
    })
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Attention Requise",
      text: "Veuillez indiquer si la facture a été payée ou non.",
  });
  }
};
