import React from 'react';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import AboutHeadSec from '../Components/AboutHeadSec';
import AboutMidSection from '../Components/AboutMidSection';
import { Helmet } from 'react-helmet';

const QuiSommesNous = () => {
  return (
    <div>
      <Helmet>
        <title>Qui Sommes Nous - MedicaPet, Clinique Vétérinaire en Tunisie</title>
        <meta name="description" content="Découvrez qui nous sommes chez MedicaPet, une clinique vétérinaire spécialisée en soins animaliers à la Marsa, Tunisie. Notre équipe offre des services de médecine vétérinaire, vaccination, et toilettage pour le bien-être de vos animaux." />
        <meta name="keywords" content="vétérinaire Tunisie, clinique vétérinaire, Médecin vétérinaire, vétérinaire Marsa, soins pour animaux, vétérinaire pour chat, vétérinaire pour chien, vaccination, toilettage" />
        <meta property="og:title" content="Qui Sommes Nous - MedicaPet, Clinique Vétérinaire en Tunisie" />
        <meta property="og:description" content="En savoir plus sur MedicaPet, une clinique vétérinaire de confiance à la Marsa, Tunisie. Nous offrons des soins vétérinaires de qualité pour animaux de compagnie." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.medicapet.net/qui-sommes-nous" />
        <meta property="og:image" content="https://www.medicapet.net/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Qui Sommes Nous - MedicaPet, Clinique Vétérinaire en Tunisie" />
        <meta name="twitter:description" content="En savoir plus sur MedicaPet, une clinique vétérinaire de confiance à la Marsa, Tunisie. Nous offrons des soins vétérinaires de qualité pour animaux de compagnie." />
        <meta name="twitter:image" content="https://www.medicapet.net/images/logo.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.medicapet.net/qui-sommes-nous" />
      </Helmet>

      <NavBar />
      <AboutHeadSec />
      <AboutMidSection />
      <Footer />
    </div>
  );
};

export default QuiSommesNous;
