import Swal from "sweetalert2";
import axiosCSRF from "../Axios/axiosConfig";

export const ModiFacture = async (id, somme) => {
    try {

        const token = localStorage.getItem('token')
    
      const response = await axiosCSRF.post('/ModifFactures', { id, somme }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
     
      if (response.status === 200) {
        window.location.reload(true)
      } else {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "erreur!",
          });
      }
    } catch (error) {
    
      console.error('Error updating facture:', error);
      alert('An error occurred while updating the facture');
    }
  };