import '../Styles/RDV.css';
import { useFormik } from 'formik';
import RdvSchema from '../Schemas/RdvSchema';
import FormError from './FormError';
import { CreeAnimal } from '../Functions/CreeAnimal';
import { useEffect } from 'react';

const FormRDV = ({ state }) => {
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    errors,
  } = useFormik({
    initialValues: {
      animal: '',
      ntel: '',
      Proprio: '',
      espx: 'Male',
    },
    validationSchema: RdvSchema,
    onSubmit: values => {
      CreeAnimal(values);
    },
  });

  // Auto-submit the form if `state` changes to true
  useEffect(() => {
    if (state) {
      handleSubmit(); // Use Formik's handleSubmit
    }
  }, [state, handleSubmit]);

  return (
    <div className='mx-auto mt-4'>
      <form className='RDVForm' onSubmit={handleSubmit} method='post'>
        <div className='w-lg-50 leftForm'>
          <input
            type='text'
            name='animal'
            placeholder="Nom de l'animal"
            className='input'
            value={values.animal}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormError displayed={errors.animal && touched.animal} error={errors.animal} />


          <div className='RadioGroup'>
            <input
              type='radio'
              id="male"
              name='espx'
              value='Male'
              checked={values.espx === 'Male'}
              onChange={handleChange}
              className='RedioIn'
            />
            <label htmlFor="male">Male</label>
            <br />
            <input
              type='radio'
              id="female"
              name='espx'
              value='Femelle'
              checked={values.espx === 'Femelle'}
              onChange={handleChange}
              className='RedioIn'
            />
            <label htmlFor="female">Femelle</label>
          </div>


          <input
            type='text'
            name='Proprio'
            placeholder="Nom Proprio"
            className='input'
            value={values.Proprio}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormError displayed={errors.Proprio && touched.Proprio} error={errors.Proprio} />

          <input
            type='number'
            name='ntel'
            placeholder="N°tel"
            className='input'
            value={values.ntel}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormError displayed={errors.ntel && touched.ntel} error={errors.ntel} />
        </div>
      </form>
    </div>
  );
};

export default FormRDV;
