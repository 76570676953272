import React from 'react'
import { Button } from 'react-bootstrap'
function RDVBtn() {
  return (
    <div>
      <Button variant='warning' className='mb-2 mx-auto mt-4' onClick={()=>{window.location.href='RDV'}}>
        <p className='m-auto text-light fw-bold text-uppercase'>Prendre Un RDV</p>
      </Button>
    </div>
  )
}

export default RDVBtn
