// src/validationSchema.js
import * as Yup from 'yup';

const ProduitSchema = Yup.object().shape({
    produit: Yup.string()
        .required('Le nom du produit est requis'),
    prix: Yup.number()
        .required('Le prix est requis')
        .positive('Le prix doit être un nombre positif'),
    type: Yup.string()
        .oneOf(['medical', 'commercial'], 'Type invalide')
        .required('Le type est requis'),
    qte: Yup.number()
        .required('La quantité est requise')
        .min(1, 'La quantité doit être au moins 1'),
    qSec: Yup.number()
        .required('Le stock de sécurité est requis')
        .min(0, 'Le stock de sécurité ne peut pas être négatif'),
    fornisseur: Yup.string()
        .required('Le nom du fournisseur est requis'),
        img: Yup.mixed()
        .nullable()  
        .notRequired()  
        .test('fileSize', 'Le fichier est trop volumineux', 
            value => !value || (value && value.size <= 2 * 1024 * 1024)) 
        .test('fileType', 'Seuls les formats JPEG et PNG sont autorisés', 
            value => !value || (value && ['image/jpeg', 'image/png'].includes(value.type)))
    
});

export default ProduitSchema;
