import React from 'react'
import ServicesCarousel from './ServicesCarousel'

const NosService = () => {
  return (
    <div className='text-center m-5'>
      <h1 className='mx-auto'>Nos Services</h1>
      <ServicesCarousel/>
    </div>
  )
}

export default NosService
