import React from 'react';
import Navbar from '../Components/NavBar';
import Footer from '../Components/Footer';
import RdvFirstSec from '../Components/RdvFirstSec';
import { Helmet } from 'react-helmet';

const RendezVous = () => {
  return (
    <div>
      <Helmet>
        <title>MedicaPet - Prendre Rendez-vous pour des Soins Vétérinaires a la Marsa</title>
        <meta name="description" content="Prenez rendez-vous avec les experts de MedicaPet pour des soins vétérinaires de qualité a la Marsa. Disponibilité pour consultations en clinique et à domicile." />
        <meta name="keywords" content="prendre rendez-vous vétérinaire, rendez-vous clinique vétérinaire, consultation vétérinaire Tunisie, rendez-vous vétérinaire Tunis, rendez-vous vétérinaire marsa, soins vétérinaires, rendez-vous vétérinaire à domicile" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="MedicaPet" />
        <meta property="og:title" content="MedicaPet - Prendre Rendez-vous pour des Soins Vétérinaires a la Marsa" />
        <meta property="og:description" content="Prenez rendez-vous avec les experts de MedicaPet pour des soins vétérinaires de qualité en Tunisie. Disponibilité pour consultations en clinique et à domicile." />
        <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta property="og:url" content="https://www.medicapet.net/rendez-vous" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="MedicaPet - Prendre Rendez-vous pour des Soins Vétérinaires a la Marsa" />
        <meta name="twitter:description" content="Prenez rendez-vous avec les experts de MedicaPet pour des soins vétérinaires de qualité en Tunisie. Disponibilité pour consultations en clinique et à domicile." />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo.png" />
        <link rel="canonical" href="https://www.medicapet.net/rendez-vous" />
      </Helmet>

      <Navbar />
      <RdvFirstSec />
      <Footer />
    </div>
  );
};

export default RendezVous;
