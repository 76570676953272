import axiosCSRF from "../Axios/axiosConfig"


export const GetProduits = async()=>{

    const token = localStorage.getItem('token')

   const response = await axiosCSRF.get('/Produits',{
    headers: {
        Authorization: `Bearer ${token}`,
      },
   });

   return response.data.data
}