import Swal from "sweetalert2";
import axiosCSRF from "../Axios/axiosConfig";

export const SupprimerProd = async(id) => {  

    const token = localStorage.getItem('token');

    await axiosCSRF.post('/suppProd', {id : id}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    .then(response => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Votre Rendez-Vous a ete pris.',
      });
    })
};
