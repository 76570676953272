import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';

function MagasinCard({produit}) {

const Notice = ()=>{
  Swal.fire("Pour commender appeler le 98 ... ... ou contactez nous sur notre page facebook ou instagrame");

}

  return (
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={`https://api.medicapet.net/storage/${produit.img}`} alt='Produit' />
      <Card.Body>
        <Card.Title>{produit.Titre}</Card.Title>
        <Card.Text>
          {produit.prix}
          {Number(produit.Quantiter) >= 1 ? <p className='text-success'>En stock</p> : <p className='text-danger'>Epuisé</p>}
        </Card.Text>
        <Button variant="info" onClick={()=>{Notice()}}>Commender</Button>
      </Card.Body>
    </Card>
  );
}

export default MagasinCard;