import React from 'react'
import logo from '../Images/logo.png'
import { Image } from 'react-bootstrap'
import '../Styles/IntroAcceuil.css'
const IntroAcceuil = () => {
  return (
    <div className='d-flex flex-column justify-content-center mt-5'>
         <Image src={logo} alt='logo' fluid className='mx-auto'/>
        <p className='mx-auto Description'>
        Medicapet offre à vos compagnons de nombreux services. Médecine interne, chirurgie, vaccination, et bien plus encore. Notre équipe est dédiée à offrir des soins personnalisés et de qualité, en veillant au bien-être et à la santé de vos animaux tout au long de leur vie.
        </p>
    </div>
  )
}

export default IntroAcceuil
