import React, { useState } from 'react';
import '../Styles/FicheForm.css';
import { CiSquarePlus } from "react-icons/ci";
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSubmit } from '../Redux/Reducer/submit';
import { useFormik } from 'formik';
import { PostFiche } from '../Functions/PostFiche';
import axiosCSRF from '../Axios/axiosConfig';

const FicheForm = ({ numtel }) => {
  const [act, setAct] = useState(false);
  const dispatch = useDispatch();
  const id_animal = useSelector((state) => state.Id.value);

  const formik = useFormik({
    initialValues: {
      motif: 'motif',
      type: '',
      act: '',
    },
    onSubmit: (values) => {

      dispatch(setSubmit(true));
      values.id = id_animal;

      const date = new Date();
      const today = new Date();

      if (values.type === 'Rappel') {
        date.setDate(date.getDate() + 15);
        values.dateRappel = values.date = date.toISOString().split('T')[0];
      } else if (values.type === 'Primo') {
        date.setFullYear(date.getFullYear() + 1);
        values.dateRappel = values.date = date.toISOString().split('T')[0];
      } else {
        values.dateRappel = null;
      }

      values.date = today.toISOString().split('T')[0];

      if (values.act === '') {
        values.act = "pas d'act";
      }

      if (numtel) {
        PostFiche(values);
      } else {
        setTimeout(async () => {
          const token = localStorage.getItem('token');
          const id = await axiosCSRF.get('/lastUser', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          values.id = id.data.data.id;
          PostFiche(values);
        }, 1200);
      }
    },
  });

  return (
    <div className='m-auto w-25'>
      <form onSubmit={formik.handleSubmit}>
        <select
          className='w-75 ficheForm'
          name='motif'
          onChange={formik.handleChange}
          value={formik.values.motif}
        >
          <option value='motif'>Motif</option>
          <option value='consultation'>Consultation</option>
          <option value='vaccination'>Vaccination</option>
          <option value='operation'>Opération</option>
        </select>

        {formik.values.motif === 'vaccination' && (
          <div className='mt-3'>
            <input
              type='radio'
              name='type'
              value='Primo'
              onChange={formik.handleChange}
              checked={formik.values.type === 'Primo'}
            />
            <label>Primovaccination</label>
            <br />
            <input
              type='radio'
              name='type'
              value='Rappel'
              onChange={formik.handleChange}
              checked={formik.values.type === 'Rappel'}
            />
            <label>Rappel</label>
            <br />
            {act ? (
              <textarea
                placeholder='Act'
                name='act'
                className='act rounded p-1'
                onChange={formik.handleChange}
                value={formik.values.act}
              />
            ) : (
              <CiSquarePlus
                className='PlusIcon bg-success mt-3 rounded'
                onClick={() => setAct(true)}
              />
            )}
          </div>
        )}

        {(formik.values.motif === 'consultation' || formik.values.motif === 'operation') && (
          <textarea
            placeholder='Act'
            name='act'
            className='act rounded p-1'
            onChange={formik.handleChange}
            value={formik.values.act}
          />
        )}

        {formik.values.motif !== 'motif' && (
          <Button className='mx-5' type='submit'>
            {numtel ? 'Sauvegarder' : 'Créer'}
          </Button>
        )}
      </form>
    </div>
  );
};

export default FicheForm;
