import React from 'react'
import MyMap from './Map'
import '../Styles/Footer.css'
import { FaFacebook,FaInstagram } from "react-icons/fa";


const Footer = () => {
  return (
    <div className='Footer'>
        <div className='mx-auto text-center'>
            <h1 className='ContactezNous m-3'>Contactez-nous</h1>
            <h3 className='m-3'>Adresse : 3 rue Abdallah elmahdi sidi Abdelaziz la marsa prés de california gym</h3>
            <h3 className='m-3'>Tel / FAX : 71 740 300</h3>
            <h3 className='m-3'>Urgence : 98 807 807</h3>
            <h3 className='m-3'>Email : <a href="mailto:Cabinetmedicapet@gmail.com">Cabinetmedicapet@gmail.com</a></h3>
            <div>
            <FaFacebook 
            className='SocialMediaIcons' 
            onClick={() => window.open('https://www.facebook.com/profile.php?id=61566455594926&mibextid=ZbWKwL', '_blank', 'noopener,noreferrer')}
            />
            <FaInstagram className='SocialMediaIconsInstagram'onClick={() => window.open('https://www.instagram.com/cabinet.veterinaire.medicapet/?igsh=ZHp5dm14ZGpxMTRw', '_blank', 'noopener,noreferrer')}
           />
            </div>
        </div>
        <div className='map-container mx-auto'>
            <MyMap/>
        </div>
    </div>
);
};

export default Footer
