import React from 'react'
import '../Styles/About.css'
import ImageTextLayout from './ImageTextLayout'
import { Image } from 'react-bootstrap'
import IconAnimal from '../Images/Main Chien.png'
import doc from '../Images/docteur_wiem.jpeg'
const AboutMidSection = () => {
    const text = "MEDICAPET est un cabinet vétérinaire à la pointe de la technologie, offrant des soins de haute qualité pour les animaux de compagnie.Nous sommes une équipe expérimentée de vétérinaire diplômé de l'école nationale de médecine vétérinaire de Sidi Thabet, assisté d'une assistante qualifiée et d'une toiletteuse professionnelle, qui se concentre sur la santé et le bien-être de vos animaux ,que ce soit pour des consultations, des vaccinations, des chirurgies, du toilettage ou des soins dentaires, le cabinet est équipé de technologies avancées pour réaliser des diagnostics précis., tels que les analyses en laboratoire. Chez MEDICAPET, nous offrons également des conseils personnalisés sur la nutrition et les soins préventifs pour garantir la meilleure qualité de vie à vos animaux."
  return (
    <div className='MidSection'>
        <div className='d-flex flex-row justify-content-center mb-5'>
        <Image src={IconAnimal} alt='chien' fluid />
         <h1 className='text-center'>Notre équipe</h1>
        </div>
      <ImageTextLayout Img={doc} Titre={''} Text={text} alt={'Docteur wiem khallouli'}/>
    </div>
  )
}

export default AboutMidSection
