import GetNumTel from '../Functions/GetNumTel';
import FicheInfo from '../Components/FicheInfo';
import React, { useState, useEffect } from 'react';
import FicheForm from './FicheForm';
import NewFicheForm from '../Components/NewFicheForm';
import { useSelector } from 'react-redux';

const FicheBody = () => {
    const [numtel, setNumtel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNumTel = async () => {
            try {
                const data = await GetNumTel();
                setNumtel(data);
            } catch (err) {
                setError('Failed to fetch data');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchNumTel();
    }, []);

    const state = useSelector((state) => state.submit.value);

    return (
        <div>
            <h1 className='text-center mt-5'>Fiche Patient</h1>
            <div className='d-flex flex-row'>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : numtel ? (
                    <FicheInfo data={numtel} />
                ) : (
                    <NewFicheForm state={state} />
                )}
                <FicheForm numtel={numtel}/>
            </div>
        </div>
    );
};

export default FicheBody;
