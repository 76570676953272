import React from 'react'
import NavBar from '../Components/NavBar'
import ListerFiche from '../Components/ListerFiches'

const ConsulterFiches = () => {
  return (
    <div>
      <NavBar/>
        <ListerFiche/>
    </div>
  )
}

export default ConsulterFiches
