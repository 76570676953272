import { useFormik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';


const ChercherFiche = () => {

  const ChercherPatient = (values) => {

       window.location.href = `/fiche?numtel=${values.numtel}`;
    
  };

  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      numtel: ''
    },
    onSubmit: ChercherPatient
  });

  return (
    <div className='d-flex flex-column text-center mt-5 mb-5'>
      <h1 className='mx-auto'>Fiche Patient</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type='number' 
          placeholder='Numéro Telephone' 
          className='text-center mx-auto w-75 rounded p-2'
          name='numtel'
          value={values.numtel}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className='mx-auto mt-3'>
          <Button 
            className='m-2'
            type='submit'
          >
            Chercher Patient
          </Button>
          <Button 
            variant='danger' 
            className='m-2'
            onClick={()=>{window.location.href ='/fiche'}}
          >
            Nouveau Patient
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChercherFiche;
