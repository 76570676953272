import { createSlice } from '@reduxjs/toolkit';

export const IdSlice = createSlice({
    name: 'Id',
    initialState: {
        value: null,
    },
    reducers: {
        setId: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setId } = IdSlice.actions;

export default IdSlice.reducer;
