import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import '../Styles/Calendar.css';
import { GetAllRdv } from '../Functions/GetAllRdv';

function Calendar() {
    const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

    const timeSlots = [
        '08:30', '09:30', '10:30',
        '11:30', '12:00', '13:00', '14:00',
        '15:30', '16:30', '17:30', '18:30'
    ];

    const initialMatrix = Array(timeSlots.length).fill(null).map(() => Array(daysOfWeek.length).fill(''));

    const [scheduleMatrix, setScheduleMatrix] = useState(initialMatrix);
    const [events, setEvents] = useState([]);
    const [currentWeek, setCurrentWeek] = useState(new Date());

    const getStartOfWeek = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    };

    const getEndOfWeek = (startOfWeek) => {
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        return endOfWeek;
    };

   useEffect(() => {
    const fetchData = async () => {
      try {
        await GetAllRdv(); 

        const fetchedEvents = await GetAllRdv()

        setEvents(fetchedEvents); 
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchData(); 
  }, []);

    useEffect(() => {
        const retrieveEventsForWeek = (startOfWeek) => {
            const newMatrix = initialMatrix.map(row => [...row]);
    
            events.forEach(event => {
                const eventDate = new Date(event.date);
                const eventDay = eventDate.getDay(); // 0 = Sunday, 1 = Monday, ...
                let columnIndex;
    
                // Directly map the eventDay to the correct index
                if (eventDay === 0) {
                    columnIndex = 6; // Sunday -> Last column (Dimanche)
                } else {
                    columnIndex = eventDay - 1; // Monday (1) -> First column (Lundi)
                }
    
                const timeIndex = timeSlots.indexOf(event.time);


eventDate.setHours(0, 0, 0, 0);

startOfWeek.setHours(0, 0, 0, 0);

                if (timeIndex !== -1 && eventDate >= startOfWeek && eventDate <= getEndOfWeek(startOfWeek)) {
                    newMatrix[timeIndex][columnIndex] = event.title;
                }
            });
    
            setScheduleMatrix(newMatrix);
        };
    
        const startOfWeek = getStartOfWeek(new Date(currentWeek));

        retrieveEventsForWeek(startOfWeek);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWeek, events]);
    

    const handleNextWeek = () => {
        setCurrentWeek(new Date(currentWeek.setDate(currentWeek.getDate() + 7)));
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(new Date(currentWeek.setDate(currentWeek.getDate() - 7)));
    };

    const startOfWeek = getStartOfWeek(new Date(currentWeek));
    const dayIndexes = Array(7).fill(null).map((_, index) => {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + index);
        return day.getDate();
    });

    const today = new Date();
    const todayIndex = today.getDay() === 0 ? 6 : today.getDay() - 1;



    return (
        <div className='calendarContainer'>
            <h3 className='mx-4'>
                Semaine du {getStartOfWeek(currentWeek).toLocaleDateString()} - {getEndOfWeek(getStartOfWeek(currentWeek)).toLocaleDateString()}
            </h3>
            <table border="1" className='calendar text-center'>
                <thead>
                    <tr>
                        <th>Temps</th>
                        {daysOfWeek.map((day, index) => (
                            <th key={index} id={index === todayIndex ? 'today' : ''}>
                                {day} <br /> {dayIndexes[index]}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {timeSlots.map((time, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>{time}</td>
                            {daysOfWeek.map((_, colIndex) => (
                                <td key={colIndex}>
                                    {scheduleMatrix[rowIndex][colIndex]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='d-flex flex-row justify-content-center'>
                <Button className='mx-1' variant='warning' onClick={handlePreviousWeek}><GrLinkPrevious /></Button>
                <Button className='mx-1' variant='success' onClick={handleNextWeek}><GrLinkNext /></Button>
            </div>
        </div>
    );
}

export default Calendar;
