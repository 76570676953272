import { createSlice } from '@reduxjs/toolkit';

export const submitSlice = createSlice({
    name: 'submit',
    initialState: {
        value: false,
    },
    reducers: {
        setSubmit: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setSubmit } = submitSlice.actions;

export default submitSlice.reducer;
