import React from 'react'

const FormError = ({displayed,error}) => {
  return (
    <div className='mx-auto'>
      {displayed && <p className='text-danger'>{error}</p>}
    </div>
  )
}

export default FormError
