import React, { useEffect, useState} from 'react'
import '../Styles/ficheinfo.css'
import { useDispatch, useSelector } from 'react-redux';
import { setId } from '../Redux/Reducer/AnimalId';
const FicheInfo = ({data}) => {

  const date = new Date().toLocaleDateString(); // Format the current date

  //const [id,setId]=useState(null)
 
const id =useSelector((state)=>state.Id.value);
const [displayer,setDisplayed]=useState(null)
const dispatch = useDispatch()
const handelClick = (animal,index)=>{
  dispatch(setId(animal.id))
  setDisplayed(index)
}
  useEffect(() => {
    if (data.length === 1) {
      dispatch(setId(data[0].id))
      setDisplayed(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      {data.map((animal,index)=>(
       id===null && (
       <div className='d-flex flex-row'  key={index} onClick={()=>{handelClick(animal,index)}}>
          <h6 className='mx-3 bg-secondary p-2 border btn'>{animal.num_tel}</h6>
          <h6 className='mx-3 bg-light p-2 border btn'>{animal.nom}</h6>
        </div>)
      ))}

      {displayer !==null && 
      (<div className='Fichinfo mt-5'> 
        <p>Nom : {data[displayer].nom_proprio}</p>
        <p>N°Tel : {data[displayer].num_tel}</p>
        <p>Animal : {data[displayer].nom}</p>
        <p>Espece : {data[displayer].espece}</p>
        <p>Date du Creation fiche : {date}</p>
      </div>)
      }
    </div>
  )
}

export default FicheInfo
