import React, { useState } from 'react';
import NavBar from './NavBar';
import { Button } from 'react-bootstrap';
import '../Styles/FormProduit.css';
import { useFormik } from 'formik';
import ProduitSchema from '../Schemas/ProduitSchema';
import FormError from './FormError';
import { PostProduit } from '../Functions/PostProduct';

const FormProduit = () => {
    const [type, setType] = useState('medical');

    const submit = async(values) => {
        values.type = type;
        console.log(values);
        await PostProduit(values)
    };

    const { handleBlur, handleChange, handleSubmit, setFieldValue, values, errors, touched} = useFormik({
        initialValues: {
            produit: '',
            prix: '',
            type: 'medical',
            qte: '',
            qSec: '',
            fornisseur: '',
            image: ''
        },
        validationSchema : ProduitSchema,
        onSubmit: submit
    });

    return (
        <div>
            <NavBar />
            <h1 className='text-center mt-5 mb-5'>Ajouter Un Produit</h1>
            <form className='d-flex flex-row' onSubmit={handleSubmit}>
                <div className='d-flex flex-column mx-auto'>
                    <input
                        type='text'
                        placeholder='Produit'
                        className='input'
                        name='produit'
                        value={values.produit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                     <FormError displayed={(errors.produit && touched.produit)} error={errors.produit}/>

                    <input
                        type='number'
                        placeholder='Prix'
                        className='input'
                        name='prix'
                        value={values.prix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                     <FormError displayed={(errors.prix && touched.prix)} error={errors.prix}/>
                    <div>
                        <input
                            type='radio'
                            className='form-check-input mb-3 mx-2'
                            name='type'
                            value='medical'
                            checked={type === 'medical'}
                            onChange={(e) => setType(e.target.value)}
                        />
                        <label>Medical</label>
                        <br />
                        <input
                            type='radio'
                            className='form-check-input mb-3 mx-2'
                            name='type'
                            value='commercial'
                            checked={type === 'commercial'}
                            onChange={(e) => setType(e.target.value)}
                        />
                        <label>Commercial</label>
                    </div>

                    <input
                        type='number'
                        placeholder='Quantiter'
                        className='input'
                        name='qte'
                        value={values.qte}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormError displayed={(errors.qte && touched.qte)} error={errors.qte}/>
                    <input
                        type='number'
                        placeholder='Stock Securité'
                        className='input'
                        name='qSec'
                        value={values.qSec}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormError displayed={(errors.qSec && touched.qSec)} error={errors.qSec}/>

                    <input
                        type='text'
                        placeholder='Fournisseur'
                        className='input'
                        name='fornisseur'
                        value={values.fornisseur}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormError displayed={(errors.fornisseur && touched.fornisseur)} error={errors.fornisseur}/>

                    <Button className='mt-3' type='submit'>Ajouter Produit</Button>
                </div>

                <div>
                    <input
                        type="file"
                        id="fileInput"
                        name='image'
                        
                        onChange={(e) => setFieldValue('image', e.target.files[0])}
                        className="file-input"
                    />
                    <FormError displayed={(errors.image && touched.image)} error={errors.image}/>
                </div>
            </form>
        </div>
    );
};

export default FormProduit;
