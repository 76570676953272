import React from 'react';
import Navbar from '../Components/NavBar';
import Footer from '../Components/Footer';
import MagasinBody from '../Components/MagasinBody';
import { Helmet } from 'react-helmet';

const Magasin = () => {
  return (
    <div>
      <Helmet>
        <title>MedicaPet Magasin - Aliments et Accessoires pour Chiens et Chats en Tunisie</title>
        <meta name="description" content="Découvrez le Magasin de MedicaPet : une large gamme d'aliments et d'accessoires pour chiens et chats en Tunisie. Produits de qualité pour tous vos besoins animaliers." />
        <meta name="keywords" content="aliments pour chiens, nourriture pour chiens, produits pour chiens, accessoires pour chiens, gamelles pour chiens, jouets pour chiens, colliers pour chiens, vêtements pour chiens, shampooing pour chiens, soins pour chiens, suppléments pour chiens, aliments bio pour chiens, croquettes pour chiens, aliments humides pour chiens, aliments pour chiots, aliments pour chiens seniors, aliments pour chats, nourriture pour chats, croquettes pour chats, aliments humides pour chats, accessoires pour chats, litières pour chats, jouets pour chats, arbres à chats, colliers pour chats, gamelles pour chats, vêtements pour chats, soins pour chats, shampooing pour chats, suppléments pour chats, accessoires pour animaux Tunisie, magasin pour animaux" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="MedicaPet" />
        <meta property="og:title" content="MedicaPet Magasin - Aliments et Accessoires pour Chiens et Chats en Tunisie" />
        <meta property="og:description" content="Découvrez le Magasin de MedicaPet : une large gamme d'aliments et d'accessoires pour chiens et chats en Tunisie. Produits de qualité pour tous vos besoins animaliers." />
        <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta property="og:url" content="https://www.medicapet.tn/magasin" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="MedicaPet Magasin - Aliments et Accessoires pour Chiens et Chats en Tunisie" />
        <meta name="twitter:description" content="Découvrez le Magasin de MedicaPet : une large gamme d'aliments et d'accessoires pour chiens et chats en Tunisie. Produits de qualité pour tous vos besoins animaliers." />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo.png" />
        <link rel="canonical" href="https://www.medicapet.tn/magasin" />
      </Helmet>

      <Navbar />
      <MagasinBody />
      <Footer />
    </div>
  );
};

export default Magasin;
