import React from 'react'
import NavBar from '../Components/NavBar'
import TableauProduit from '../Components/TableauProduit'
import AdminNavBar from '../Components/AdminNavBar'
import { Button } from 'react-bootstrap'

const Stock = () => {
  return (
    <div>
      <NavBar/>
      <AdminNavBar/>
      <TableauProduit/>
      <Button variant='danger' className='m-5'
      onClick={()=>{
        window.location.href = '/AjouterProd'
      }}
      >Ajouter Produit</Button>
      
    </div>
  )
}

export default Stock
