import React, { useEffect, useState } from 'react'
import MagasinCard from './MagasinCard'
import { ProduitMagasin } from '../Functions/GetProduitMagasin'

const MagasinBody = () => {
  const [produits, setProduit] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await ProduitMagasin()
        setProduit(data || [])  // Ensure data is an array
      } catch (error) {
        console.error("Error fetching products:", error)
        setProduit([])  // Fallback to an empty array on error
      }
    }

    fetch()
  }, [])

  return (
    <div className='mt-5'>
      <h2 className='text-center'>Produits populaires</h2>
      <div className='d-flex gap-5 mt-5 justify-content-center flex-wrap'>
        {Array.isArray(produits) && produits.length > 0 ? (
          produits.map((produit, index) => (
            <MagasinCard key={index} produit={produit} />
          ))
        ) : (
          <p className='text-center'>Pas de produits disponibles pour le moment.</p>
        )}
      </div>
    </div>
  )
}

export default MagasinBody
