import axiosCSRF from "../Axios/axiosConfig";
import { encryptPassword } from "./Cryptage";

export const LoginReq = async (data) => {
    try {
       const pwd = encryptPassword(data.mdp)
        const response = await axiosCSRF.post('/login', { password: pwd, email: data.email });


        return response.data;
    } catch (e) {

        if (e.response && e.response.status === 422) {

            return "422";
        }

        throw e;
    }
};
