import Swal from "sweetalert2";
import axiosCSRF from "../Axios/axiosConfig";
import { CreeAnimal } from "./CreeAnimal";

export const PrendreRdv = async(data) => {  


    const requesRDVtData = {
        nom_animal: data.animal, 
        num_tel: data.ntel,
        date: data.DateRDV,
        temp: data.temp
    };

    await CreeAnimal(data)

    await axiosCSRF.post('RDV', requesRDVtData)
    .then(response => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Votre Rendez-Vous a ete pris.',
      });
    })
};
