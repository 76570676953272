import React from 'react'
import { Button } from 'react-bootstrap'

const PrendreRDVSection = () => {
  return (
    <div 
    className='d-flex flex-column justify-content-center align-items-center mt-5 mx-auto rounded p-4'
    style={{backgroundColor:'#f48d34',color:'white',width:'90%'}}
    >
      <h1>VOUS SOUHAITEZ PRENDRE UN RENDEZ-VOUS</h1>
      <Button 
      variant='light' 
      style={{color:'#f48d34'}}
      className='fw-bold mt-3'
      onClick={()=>{window.location.href ="RDV"}}
      >Cliquez ici</Button>
    </div>
  )
}

export default PrendreRDVSection
