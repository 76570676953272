import axiosCSRF from "../Axios/axiosConfig";


const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return `${year}/${month}/${day}`;
};
const formatTime = (timeStr) => {
    return timeStr.split(':').slice(0, 2).join(':');
};
export const GetAllRdv = async()=>{  
  const token = localStorage.getItem('token')
  const response = await axiosCSRF.get('/RDV', {
    headers: {
      Authorization: `Bearer ${token}`,
    },});
  const rawEvents = response.data.RDV;

  // Transform and format the dates
  const formattedEvents = rawEvents.map(event => ({
      date: formatDate(event.date),
      time: formatTime(event.time),
      title: event.title
  }));

  console.log(formattedEvents)
  return formattedEvents
}