import React from 'react'
import NavBar from '../Components/NavBar'
import AdminNavBar from '../Components/AdminNavBar'
import MedicamentBody from '../Components/MedicamentBody'



const Medicament = () => {
  return (
    <div>
      <NavBar/>
      <AdminNavBar/>
        <MedicamentBody/>
    </div>
  )
}

export default Medicament
