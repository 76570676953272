import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Images/logo.png'
import Image from 'react-bootstrap/Image';
import '../Styles/Logo.css'
function NavBar() {
  return (
    <div>
<Navbar  data-bs-theme="light" collapseOnSelect expand="lg" >
        <Container>
          <Navbar.Brand href="/">
            <Image src={logo} alt='logo' fluid className="img-fluid logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="/" className='mx-3'><p className=' navSec'>Accueil</p></Nav.Link>
            <Nav.Link href="/about" className='mx-3 navSec'><p className=' navSec'>Qui sommes-nous</p></Nav.Link>
            <Nav.Link href="/RDV" className='mx-3 navSec'><p className=' navSec'>Prendre RDV</p></Nav.Link>
            <Nav.Link href="/store" className='mx-3 navSec'><p className=' navSec'>Magasin</p></Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBar
