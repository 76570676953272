import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import NavBar from '../Components/NavBar';
import { ChercherClient } from '../Functions/chercherClient';

const CreeFacture = () => {
    const [numtel, setNumtel] = useState('');
   
    const submit = async () => {
        const clientNom = await ChercherClient(numtel); // Fetch the client name
        console.log(clientNom);
    
        if (clientNom) {
            window.location.href = `/facture?numtel=${numtel}&nom=${clientNom}`;
        }
    };

    return (
        <div>
            <NavBar/>
            <div className='d-flex align-content-center justify-content-center mt-5'>
            <input 
                type='number' 
                value={numtel} 
                name='numtel' 
                onChange={(e) => setNumtel(e.target.value)} 
                className='w-75'
                placeholder='Numero Telephone'
            />

            <Button 
                onClick={() => submit()}
            >
                Créé Facture
            </Button>
            </div>
        </div>
    );
}

export default CreeFacture;
