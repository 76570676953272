import React, { useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import loginImg from '../Images/LoginImg.png'
import '../Styles/Login.css'
import { useFormik } from 'formik'
import LoginSchema from '../Schemas/LoginShcema'
import FormError from './FormError';
import { LoginReq } from '../Functions/Login'
import { GetUser } from '../Functions/GetToken'

const FormLogin = () => {

    const [loading,setLoading]= useState(false)

    const{handleBlur,handleChange,handleSubmit,touched,values,errors} = useFormik({
        
        initialValues : {
            'email' :'',
            'mdp':''
        },

        validationSchema : LoginSchema,

        onSubmit : async()=>{
            setLoading(true)
           const response =  await LoginReq(values)
            console.log(response)
            if(response === "422"){
                errors.mdp = 'Mot de passe inccorrecte'
                setLoading(false)
            }
            await GetUser()
           window.location.href='/Dashboard'
        }
    })

  return (
    <div className='d-flex felx-row container mt-5 formContainer p-0'>

        <div className='LoginImg mx-auto w-50'>
        <Image src={loginImg} alt='image_login' fluid className='h-100'/>
        </div>
     {!loading ?
     
     (<form method='POST' className='d-flex flex-column m-auto w-50' onSubmit={handleSubmit}>
            <input 
            type='email' 
            className='inputLogin'
            placeholder='Email'
            value={values.email}
            name='email'
            onChange={handleChange}
            onBlur={handleBlur}
            />
            <FormError displayed={errors.email && touched.email} error={errors.email} />
            <input 
            type='password' 
            className='inputLogin'
            placeholder='Mot de Passe'
            value={values.mdp}
            name='mdp'
            onChange={handleChange}
            onBlur={handleBlur}
            />
            <FormError displayed={errors.mdp && touched.mdp} error={errors.mdp} />
            <Button 
            type='submit'
            className='mt-3 m-auto w-25'
            >Login</Button>
        </form> ): <p className='mx-auto'>Chargement</p>
     
     
     }
        
    
    </div>
  )
}

export default FormLogin
