import React, { useEffect, useState } from 'react';
import { GetFacture } from '../Functions/GetFacture';
import NavBar from '../Components/NavBar';
import '../Styles/MesFacture.css';
import { Button } from 'react-bootstrap';



const MesFactures = () => {
  const [factures, setFactures] = useState([]);





  useEffect(() => {
    const fetchData = async () => {
      const data = await GetFacture();
      setFactures(data.factures); 
    };
    fetchData();
  }, []);



 

  return (
    <div className="factures-container">
      <NavBar />
      <h2 className="title">Mes Factures</h2>
      <div className="facture-list">
        {factures.map((facture) => (
          <div key={facture.id} className="facture-item">
            <p><strong>Date:</strong> {facture.date}</p>
            <p><strong>Numéro de Téléphone:</strong> {facture.num_tel}</p>
            <p><strong>Somme:</strong> {facture.somme} Dt</p>
            <p><strong>Statut:</strong> {facture.statue}</p>
          </div>
        ))}
      </div>
      <Button className='m-4' onClick={()=>{window.location.href = '/NonPayer'}}>Non Payer</Button>
    </div>
  );
};

export default MesFactures;
