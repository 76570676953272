import React from 'react'
import '../Styles/Landing.css'
import RDVBtn from './RDVBtn'
function LandingSection() {
  return (
    <div className='laningDiv d-flex flex-column justify-content-center'>
      <h1>Medicapet</h1>
      <h5>Prenez soin de vos compagnons, nous prenons soin de leur santé.</h5>
      <RDVBtn/>
    </div>
  )
}

export default LandingSection
