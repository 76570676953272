import axiosCSRF from "../Axios/axiosConfig"


export const CountQsec = async()=>{

    const token = localStorage.getItem('token')

   const response = await axiosCSRF.get('/count',{
    headers: {
        Authorization: `Bearer ${token}`,
      },
   });

   return response.data.data
}