import React from 'react'
import { MdDashboard } from "react-icons/md";
import '../Styles/DashboardIcon.css'
import { jwtDecode } from 'jwt-decode';
const DashboardIcon = () => {
    const token = localStorage.getItem('token')
    const id = jwtDecode(token).id
  return (
    <div className='DashboardFloat'>
      {id === 2 && <MdDashboard className='w-100 h-100 text-info' 
      onClick={()=>{window.location.href = '/Dashboard'}}
      />}
    </div>
  )
}

export default DashboardIcon
