import React from 'react'
import { Image } from 'react-bootstrap'
import '../Styles/About.css'

const ImageTextLayout = ({Img,Titre,Text,alt}) => {
  return (
    <div>
       <div className='CabinetContainer'>
        <Image src={Img} alt={alt} className='ImageCabinet'/>
        <div>
            <h3>{Titre}</h3>
            <p className='text' >{Text} </p>
        </div>
      </div>
    </div>
  )
}

export default ImageTextLayout
