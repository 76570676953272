import React from 'react'
import '../Styles/RoundedCard.css'
function RoundedCard({service}) {
  return (
    <div className='RoundedCard m-3 d-flex align-items-center justify-content-center'>
      <h3>{service}</h3>
    </div>
  )
}

export default RoundedCard
