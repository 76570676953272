import Swal from 'sweetalert2';
import axiosCSRF from '../Axios/axiosConfig';

export const ChercherClient = async (numtel) => {
    try {
       
        const token = localStorage.getItem('token');

        
        if (!token) {
            throw new Error("Token not found"); 
        }

        
        const response = await axiosCSRF.post('/nom', { numtel: numtel }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

       
        return response.data.nom_proprio;

    } catch (e) {
        
        console.error(e);

        
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Ce client n'existe pas",
        });

        return null; 
    }
};
